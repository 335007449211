import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { AREA_DISTANCE_DEFAULT_RADIUS, AREA_DISTANCE_MAX_RADIUS, AREA_DISTANCE_MIN_RADIUS } from '@/core/constants';
import { SEARCH_CONFIG_STORE } from '../../../../store/modules/search-config';
export default defineComponent({
    name: 'project-areacode-filter',
    emits: ['change'],
    props: {
        defaultFilter: {
            type: Object
        }
    },
    data() {
        return {
            AREA_DISTANCE_MAX_RADIUS,
            AREA_DISTANCE_MIN_RADIUS,
            AREA_DISTANCE_DEFAULT_RADIUS,
            areaCodeFilter: {
                postcode: '',
                distance: AREA_DISTANCE_DEFAULT_RADIUS,
            },
            areaCodeRules: {
                postcode: [
                    {
                        required: true,
                        validators: () => true,
                        trigger: 'change',
                    }
                ],
                distance: [
                    {
                        required: true,
                        trigger: 'change'
                    }
                ]
            },
        };
    },
    async created() {
        if (this.defaultFilter) {
            this.areaCodeFilter = Object.assign(this.areaCodeFilter, this.defaultFilter);
        }
        await this.getUserSearchConfigByFilterKey({ filterKey: 'areacode' })
            .then((response) => {
            if (response && response.metadata) {
                this.areaCodeFilter.postcode = response.metadata.postcode;
                this.areaCodeFilter.distance = response.metadata.distance;
            }
        });
    },
    computed: {
        ...mapGetters(SEARCH_CONFIG_STORE, ['userSearchCriteria']),
    },
    methods: {
        ...mapActions(SEARCH_CONFIG_STORE, ['saveUserSearchConfig', 'getUserSearchConfigByFilterKey']),
        formatTooltip(value) {
            return `${value} miles`;
        },
        savePostcodeFilter() {
            this.saveUserSearchConfig({
                filterKey: 'areacode',
                metadata: {
                    postcode: this.areaCodeFilter.postcode,
                    distance: this.areaCodeFilter.distance,
                },
            });
        },
        emitFilterChange() {
            this.$refs.areaCodeFilterForm.validate((valid) => {
                if (valid) {
                    this.$emit('change', {
                        postcode: this.areaCodeFilter.postcode,
                        radius: this.areaCodeFilter.distance
                    });
                    this.savePostcodeFilter();
                }
            });
        }
    }
});
