import { defineComponent } from 'vue';
export default defineComponent({
    name: 'map-layout-tp-card-item',
    emits: ['selectProject'],
    props: {
        selectedProject: {
            type: Object,
            default: null,
        },
        project: {
            type: Object,
            required: true,
        },
        averageQuoteValue: {
            type: Function,
        }
    },
    data: () => {
        return {};
    },
    methods: {
        viewProject(projectId) {
            this.$router.push({ name: 'project-overview', params: { projectId } });
        },
        selectProject() {
            this.$emit('selectProject', this.project);
        },
    }
});
