import { Loader } from '@googlemaps/js-api-loader';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PROJECTS_STORE } from '../../../../store/modules/projects';
import { SEARCH_CONFIG_STORE } from '../../../../store/modules/search-config';
import { USERS_STORE } from '../../../../store/modules/users';
import { PAGE_LIMIT } from '../../../constants';
import MapLayoutPOCardItem from './map-card-items/MapLayoutPOCardItem.vue';
import MapLayoutPOPopup from './map-card-items/MapLayoutPOPopup.vue';
import MapLayoutTPCardItem from './map-card-items/MapLayoutTPCardItem.vue';
import MapLayoutTPPopup from './map-card-items/MapLayoutTPPopup.vue';
export default defineComponent({
    name: 'project-map',
    components: {
        MapLayoutPOCardItem,
        MapLayoutPOPopup,
        MapLayoutTPCardItem,
        MapLayoutTPPopup
    },
    props: {
        projects: {
            type: Array,
            required: true,
        },
        averageQuoteValue: {
            type: Function,
        }
    },
    created() {
        this.loadMap();
    },
    data() {
        return {
            loadingMarkerProjects: true,
            selectedMarker: null,
            selectedProject: null,
            google: null,
            map: null,
            mapPopup: null,
            mapMarkers: {},
            totalPages: null,
            pinMarkerSvg: {
                path: 'M17.4974 0C12.8585 0.00558077 8.41117 1.86585 5.13098 5.17277C1.85078 8.47968 0.00553568 12.9632 0 17.6399C0 22.4611 2.73756 27.9755 8.14208 34.0251C10.5486 36.7128 13.1647 39.2021 15.9652 41.469C16.404 41.8132 16.9441 42 17.5 42C18.0559 42 18.596 41.8132 19.0348 41.469C21.8353 39.2021 24.4513 36.7128 26.8579 34.0251C32.2598 27.9623 35 22.4611 35 17.6399C34.9945 12.9623 33.1485 8.47798 29.8672 5.17092C26.5858 1.86385 22.1372 0.00418556 17.4974 0Z',
                fillColor: '#858585',
                fillOpacity: 0.8,
                strokeWeight: 0,
                rotation: 0,
                scale: 1,
            },
            mapMarkerLabelOptions: {
                text: '...',
                color: '#fff',
                fontFamily: 'Avenir Medium',
                fontWeight: 'bold',
                fontSize: '16px'
            },
            pagination: {
                limit: PAGE_LIMIT
            },
        };
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(SEARCH_CONFIG_STORE, ['userSearchCriteria']),
        isRouteMyJobs() {
            return this.$route.meta.myJobs;
        },
        getFilterLabelResults() {
            return this.selectedMarker.get('projects').length
                + (this.selectedMarker.get('pagination')
                    && this.selectedMarker.get('pagination').page < this.selectedMarker.get('pagination').totalPages ? '+' : '');
        },
        mapMarkerArray() {
            return (typeof this.mapMarkers === 'object' && this.mapMarkers !== null) ? Object.values(this.mapMarkers) : [];
        },
        centerLatLong() {
            let latLong = { lat: parseFloat(this.user.latitude), lng: parseFloat(this.user.longitude) };
            if (this.userSearchCriteria && this.userSearchCriteria.postcode && this.mapMarkers[this.userSearchCriteria.postcode]) {
                const project = this.mapMarkers[this.userSearchCriteria.postcode].get('projects')[0];
                latLong = { lat: parseFloat(project.latitude), lng: parseFloat(project.longitude) };
            }
            return latLong;
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectPageList', 'getProjectJobs']),
        sortChange() {
            this.$emit('sort');
        },
        enablePopUpProject(project) {
            if (this.mapPopup) {
                this.mapPopup.position = new this.google.maps.LatLng(project.latitude, project.longitude);
                this.mapPopup.visible = true;
                this.mapPopup.draw();
            }
        },
        selectProject(project) {
            this.selectedProject = project;
            this.enablePopUpProject(project);
            if (this.map) {
                this.map.panTo(new this.google.maps.LatLng(project.latitude, project.longitude));
            }
        },
        loadMap() {
            const loader = new Loader({
                apiKey: 'AIzaSyA9baWZPytZY8YUbjl1v-NmzTzDc75y1bE',
                version: 'weekly',
            });
            loader.load().then((google) => {
                this.google = google;
                this.initiateMap();
            });
        },
        async initiateMap() {
            this.map = new this.google.maps.Map(document.getElementById('map-view'), {
                streetViewControl: false,
                clickableIcons: false,
                center: this.centerLatLong,
                zoom: 15,
                maxZoom: 18,
                minZoom: 10,
                styles: [
                    {
                        stylers: [
                            {
                                weight: 1
                            }
                        ]
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry',
                        stylers: [
                            {
                                visibility: 'off'
                            }
                        ]
                    },
                    {
                        featureType: 'landscape.natural',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'landscape.natural.landcover',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'landscape.natural.terrain',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'poi',
                        stylers: [
                            {
                                visibility: 'off'
                            }
                        ]
                    },
                    {
                        featureType: 'poi.park',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.icon',
                        stylers: [
                            {
                                visibility: 'off'
                            }
                        ]
                    },
                    {
                        featureType: 'road.arterial',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'road.highway',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'road.highway.controlled_access',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'road.local',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'transit',
                        stylers: [
                            {
                                visibility: 'off'
                            }
                        ]
                    },
                    {
                        featureType: 'transit.line',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'transit.station',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'transit.station.airport',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'transit.station.bus',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'transit.station.rail',
                        stylers: [
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'water',
                        stylers: [
                            {
                                color: '#1a50b7'
                            },
                            {
                                lightness: 35
                            }
                        ]
                    }
                ]
            });
            this.map.setCenter(this.centerLatLong);
            this.map.addListener('click', () => {
                this.selectedMarker = null;
                this.selectedProject = null;
                this.mapPopup.visible = false;
                this.mapMarkerArray.forEach((m) => {
                    m.set('active', false);
                    m.setIcon({
                        ...this.pinMarkerSvg,
                        labelOrigin: new this.google.maps.Point(17, 20),
                        anchor: new this.google.maps.Point(15, 30),
                    });
                });
            });
            if (this.projects) {
                await this.generateMapMarkers();
                this.generateMapPopUp();
            }
        },
        initScrollPaginationListener() {
            const scrollContainer = document.querySelector('#mapListItems');
            scrollContainer.addEventListener('wheel', (evt) => {
                evt.preventDefault();
                scrollContainer.scrollLeft += (Math.sign(evt.deltaY) >= 0 || Math.sign(evt.deltaY) === 1 ? evt.deltaY + 400 : evt.deltaY + -400);
            });
            scrollContainer.addEventListener('scroll', () => {
                if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth && !this.loadingMarkerProjects) {
                    const selectedMarkerPagination = this.selectedMarker.get('pagination');
                    if (selectedMarkerPagination.page < selectedMarkerPagination.totalPages) {
                        this.loadProjectsByPostCode({
                            page: selectedMarkerPagination.page + 1,
                        });
                        this.selectedMarker.set('scrollLeft', scrollContainer.scrollLeft);
                    }
                }
            });
        },
        removeMarkers() {
            if (Array.isArray(this.mapMarkerArray)) {
                this.mapMarkerArray.forEach((marker) => {
                    marker.setMap(null);
                });
            }
        },
        async generateMapMarkers() {
            this.mapMarkers = {};
            const mapMarkersProjectsPromises = [];
            for (const currentItem of this.projects.entries()) {
                const project = currentItem[1];
                const currentProject = project;
                // check if a marker already exists for this postcode
                if (this.mapMarkers[currentProject.postcode]) {
                    const projects = this.mapMarkers[currentProject.postcode].get('projects');
                    projects.push(project);
                    // eslint-disable-next-line no-continue
                    continue;
                }
                let projectPromise = null;
                const filters = {
                    distinctPostcode: false,
                    noCommit: true,
                    page: 1,
                    limit: this.pagination.limit,
                    postcodes: [currentProject.postcode],
                };
                if (this.isRouteMyJobs) {
                    projectPromise = this.getProjectJobs(filters);
                }
                else {
                    projectPromise = this.getProjectPageList(filters);
                }
                mapMarkersProjectsPromises.push(projectPromise);
                if (currentProject.latitude && currentProject.longitude) {
                    const latitude = parseFloat(currentProject.latitude);
                    const longitude = parseFloat(currentProject.longitude);
                    const mapMarkerIcon = {
                        ...this.pinMarkerSvg,
                        labelOrigin: new this.google.maps.Point(17, 20),
                        anchor: new this.google.maps.Point(15, 30),
                    };
                    const marker = new this.google.maps.Marker({
                        position: { lat: latitude, lng: longitude },
                        label: { ...this.mapMarkerLabelOptions, text: '...' },
                        icon: mapMarkerIcon,
                        map: this.map,
                    });
                    marker.addListener('mouseover', () => {
                        marker.setIcon({ ...mapMarkerIcon, fillColor: '#1A50B7' });
                    });
                    marker.addListener('mouseout', () => {
                        if (marker.get('active') !== true) {
                            marker.setIcon({ ...mapMarkerIcon, fillColor: '#858585' });
                        }
                    });
                    marker.addListener('click', () => {
                        this.mapMarkerArray.forEach((m) => {
                            m.set('active', false);
                            m.setIcon({ ...mapMarkerIcon, fillColor: '#858585' });
                        });
                        marker.set('active', true);
                        marker.setIcon({ ...mapMarkerIcon, fillColor: '#1A50B7' });
                        this.selectedMarker = marker;
                        this.map.panTo(new this.google.maps.LatLng(project.latitude, project.longitude));
                        const markerProjects = marker.get('projects');
                        if (Array.isArray(markerProjects) && markerProjects.length <= 1) {
                            this.loadProjectsByPostCode({
                                page: 1,
                                limit: this.pagination.limit
                            });
                        }
                    });
                    let markerProjects = marker.get('projects');
                    if (!Array.isArray(markerProjects)) {
                        markerProjects = [];
                    }
                    markerProjects.push(project);
                    marker.set('projects', markerProjects);
                    this.mapMarkers[project.postcode] = marker;
                }
            }
            const mapMarkersProjectsResponses = await Promise.all(mapMarkersProjectsPromises);
            if (mapMarkersProjectsResponses) {
                mapMarkersProjectsResponses.forEach((response, i) => {
                    if (typeof this.projects[i] !== 'undefined') {
                        const marker = this.mapMarkers[this.projects[i].postcode];
                        if (marker) {
                            marker.setLabel({ ...this.mapMarkerLabelOptions, text: response.items.length.toString() });
                            marker.set('projects', response.items);
                            marker.set('pagination', {
                                page: 1,
                                limit: this.pagination.limit,
                                totalPages: response.meta.totalPages,
                            });
                        }
                    }
                });
            }
            await this.setInitialLoadProjects();
            this.loadingMarkerProjects = false;
        },
        async setInitialLoadProjects() {
            const defaultPostcode = this.user.postcode;
            if (this.mapMarkers[defaultPostcode]) {
                const mapMarkerIcon = {
                    ...this.pinMarkerSvg,
                    labelOrigin: new this.google.maps.Point(17, 20),
                    anchor: new this.google.maps.Point(15, 30),
                };
                this.selectedMarker = this.mapMarkers[defaultPostcode];
                this.selectedMarker.set('active', true);
                this.selectedMarker.setIcon({ ...mapMarkerIcon, fillColor: '#1A50B7' });
                const projects = this.selectedMarker.get('projects');
                const { latitude, longitude } = projects[0];
                if (latitude && longitude) {
                    this.map.panTo(new this.google.maps.LatLng(latitude, longitude));
                }
            }
            else {
                this.map.panTo(this.centerLatLong);
            }
        },
        closePopUp() {
            if (this.mapPopup) {
                this.mapPopup.visible = false;
                this.mapPopup.draw();
            }
            this.selectedMarker = false;
        },
        generateMapPopUp() {
            /**
             * A customized popup on the map.
             */
            class Popup extends this.google.maps.OverlayView {
                constructor(position, content) {
                    super();
                    this.visible = false;
                    this.position = position;
                    content.classList.add('popup-bubble');
                    // This zero-height div is positioned at the bottom of the bubble.
                    const bubbleAnchor = document.createElement('div');
                    bubbleAnchor.classList.add('popup-bubble-anchor');
                    bubbleAnchor.appendChild(content);
                    // This zero-height div is positioned at the bottom of the tip.
                    this.containerDiv = document.createElement('div');
                    this.containerDiv.classList.add('popup-container');
                    this.containerDiv.appendChild(bubbleAnchor);
                    // Optionally stop clicks, etc., from bubbling up to the map.
                    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
                }
                /** Called when the popup is added to the map. */
                onAdd() {
                    this.getPanes().floatPane.appendChild(this.containerDiv);
                }
                /** Called when the popup is removed from the map. */
                onRemove() {
                    if (this.containerDiv.parentElement) {
                        this.containerDiv.parentElement.removeChild(this.containerDiv);
                    }
                }
                /** Called each frame when the popup needs to draw itself. */
                draw() {
                    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
                    // Hide the popup when it is far out of view.
                    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 && this.visible
                        ? 'block'
                        : 'none';
                    if (display === 'block') {
                        this.containerDiv.style.left = `${divPosition.x}px`;
                        this.containerDiv.style.top = `${divPosition.y - 100}px`;
                    }
                    if (this.containerDiv.style.display !== display) {
                        this.containerDiv.style.display = display;
                    }
                }
            }
            this.mapPopup = new Popup(new this.google.maps.LatLng(0, 0), document.getElementById('map-popup-content'));
            this.mapPopup.setMap(this.map);
        },
        async loadProjectsByPostCode({ page }) {
            this.loadingMarkerProjects = true;
            const initialProject = this.selectedMarker.get('projects');
            let data = null;
            const filters = {
                distinctPostcode: false,
                noCommit: true,
                page,
                limit: this.pagination.limit,
                postcodes: [initialProject[0].postcode],
            };
            if (this.isRouteMyJobs) {
                data = await this.getProjectJobs(filters);
            }
            else {
                data = await this.getProjectPageList(filters);
            }
            const response = data;
            const projects = response.items;
            this.totalPages = response.meta.totalPages;
            this.selectedMarker.setLabel({ ...this.mapMarkerLabelOptions, text: response.meta.totalItems.toString() });
            if (!this.selectedMarker.get('pagination') || initialProject.length === projects.length) {
                this.selectedMarker.set('projects', projects);
                this.selectedMarker.set('pagination', {
                    page,
                    limit: this.pagination.limit,
                    totalPages: response.meta.totalPages,
                });
                this.loadingMarkerProjects = false;
            }
            else if (response.meta.totalPages <= page) {
                this.totalPages = 1;
                const existingProjects = this.selectedMarker.get('projects');
                this.selectedMarker.set('projects', existingProjects.concat(projects));
                this.selectedMarker.set('pagination', {
                    page,
                    limit: this.pagination.limit,
                    totalPages: response.meta.totalPages,
                });
                this.loadingMarkerProjects = false;
                setTimeout(() => {
                    const scrollContainer = document.querySelector('#mapListItems');
                    if (scrollContainer) {
                        scrollContainer.scrollLeft = this.selectedMarker.get('scrollLeft');
                    }
                }, 20);
            }
        },
        prevClick() {
            const element = document.getElementById('mapListItems');
            if (element) {
                element.scrollLeft -= 400;
            }
        },
        nextClick() {
            const element = document.getElementById('mapListItems');
            if (element) {
                element.scrollLeft += 400;
            }
        }
    }
});
