import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECTS_CACHE } from '@/store/modules/attachment-cache/constants';
export default defineComponent({
    name: 'project-layout-A-image',
    props: {
        project: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            src: ''
        };
    },
    mounted() {
        this.loadAttachments();
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        async loadAttachments() {
            const { attachment } = this.$props.project;
            this.getAttachments({
                name: PROJECTS_CACHE,
                attachments: [{ attachment }],
                returnFirstOnly: true
            })
                .then(({ src }) => {
                this.src = src;
            }).catch(() => { });
        },
        setPlaceHolderImage(e) {
            // eslint-disable-next-line global-require
            e.target.src = require('@/assets/images/po-project-empty-placeholder.jpg');
        }
    }
});
