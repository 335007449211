<template>
<el-popover
  class="sort-popover-container"
  placement="bottom-end"
  trigger="click">
  <div class="sort-container padding-4">
    <h4 class="sort-header">Sort By</h4>
    <ul class="sort-list">
      <li v-for="(sortField, i) in sortFields" :key="i" @click="toggleSort(sortField)">
        <inline-svg class="sort-icon" :src="require('@/assets/icons/sort-desc.svg')" width="16" height="16" v-if="sortField.sortOrder === 'ASC'"></inline-svg>
        <inline-svg class="sort-icon" :src="require('@/assets/icons/sort-asc.svg')" width="16" height="16" v-else-if="sortField.sortOrder === 'DESC'"></inline-svg>
        {{ sortField.label }}
      </li>
    </ul>
  </div>
  <template v-slot:reference>
    <el-button type="default" class="sort-popover" round><inline-svg class="sort-icon" :src="require('@/assets/icons/sort-icon.svg')"></inline-svg> Sort</el-button>
  </template>
</el-popover>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'sort',
  emits: ['sort'],
  props: {
    sortFields: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {},
  methods: {
    toggleSort(sortField) {
      // eslint-disable-next-line no-param-reassign
      sortField.sortOrder = (sortField.sortOrder === 'ASC') ? 'DESC' : 'ASC';
      this.$emit('sort', { sortBy: [`${sortField.sortBy}-${sortField.sortOrder}`] });
    }
  }
});
</script>
<style lang="scss" scoped>
.sort-popover {
  background: transparent;
  border: 0;
  font-family: 'Avenir Medium';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 22px;
  color: #264FD5;
  padding: 0;
}

.sort-icon {
  position: relative;
  top: 1px;
}
</style>
<style lang="scss">
.sort-container {
  .sort-header {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    color: #010E38;
    margin: 0;
    margin-bottom: 16px;
  }

  .sort-list {
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      list-style: none;
      font-family: 'Avenir';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      position: relative;
      padding-left: 20px;
      /* 4887FF - Button Hover State */
      color: #4887FF;

      .sort-icon {
        position: absolute;
        top: -3px;
        left: 0;
      }
    }
  }
}
</style>
