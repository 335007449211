import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PROJECTS_STORE } from '../../../../store/modules/projects';
export default defineComponent({
    name: 'project-quotestatus-filter',
    emits: ['change'],
    props: {
        projectQuoteStatusEnum: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            projectQuoteStatusCountState: [],
            selectedQuoteStatus: [],
        };
    },
    computed: {
        filteredProjectQuoteStatus() {
            if (!this.projectQuoteStatusEnum.length) {
                return this.projectQuoteStatusCountState;
            }
            if ((this.projectQuoteStatusCountState && this.projectQuoteStatusCountState.length)) {
                return this.projectQuoteStatusCountState.filter((projectQuoteItem) => {
                    return this.projectQuoteStatusEnum.includes(projectQuoteItem.id);
                });
            }
            return this.projectQuoteStatusCountState;
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectQuoteStatusWithCountState']),
        onSelectStatus(checked, status) {
            const foundIndex = this.selectedQuoteStatus.indexOf(status);
            if (checked) {
                if (foundIndex === -1) {
                    this.selectedQuoteStatus.push(status);
                }
            }
            else if (foundIndex > -1) {
                this.selectedQuoteStatus.splice(foundIndex, 1);
            }
            this.$emit('change', {
                projectQuoteStatusIds: this.selectedQuoteStatus
            });
        }
    },
    async created() {
        const projectQuoteStatusCountState = await this.getProjectQuoteStatusWithCountState('my-quotes');
        if (projectQuoteStatusCountState) {
            this.projectQuoteStatusCountState = projectQuoteStatusCountState;
        }
    }
});
