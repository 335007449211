import { defineComponent } from 'vue';
import { ProjectJobStatusEnum, ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'map-layout-PO-card-item',
    props: {
        selectedProject: {
            type: Object,
            default: null,
        },
        project: {
            type: Object,
            required: true,
        }
    },
    data: () => {
        return {
            ProjectJobStatusEnum,
            ProjectQuoteStatusEnum,
        };
    },
    computed: {
        projectHasStarted() {
            const status = this.project.projectJobStatus.id;
            let hasStarted = false;
            switch (status) {
                case ProjectJobStatusEnum.QUOTE_CONFIRMED:
                case ProjectJobStatusEnum.IN_PROGRESS:
                case ProjectJobStatusEnum.COMPLETED:
                    hasStarted = true;
                    break;
                default:
            }
            return hasStarted;
        },
        projectConfirmedQuote() {
            let quote = null;
            if (this.projectHasStarted) {
                quote = this.project.projectQuotes.find((q) => {
                    return q.projectQuoteStatus.id === ProjectQuoteStatusEnum.QUOTE_CONFIRMED
                        || q.projectQuoteStatus.id === ProjectQuoteStatusEnum.IN_PROGRESS
                        || q.projectQuoteStatus.id === ProjectQuoteStatusEnum.COMPLETED;
                });
            }
            return quote;
        }
    },
    methods: {
        selectProject() {
            this.$emit('selectProject', this.project);
        },
    }
});
