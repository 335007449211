import { defineComponent } from 'vue';
import ProjectAreacodeFilter from '@/core/components/project/project-filter/ProjectAreaCodeFilter.vue';
import ProjectCategoryFilter from '@/core/components/project/project-filter/ProjectCategoryFilter.vue';
import ProjectQuoteStatusFilter from '@/core/components/project/project-filter/ProjectQuoteStatusFilter.vue';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'project-quote-filter-component',
    emits: ['filter-change'],
    components: {
        ProjectQuoteStatusFilter,
        ProjectCategoryFilter,
        ProjectAreacodeFilter,
    },
    props: {
        defaultFilters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            activeFilter: 'projectQuoteStatus',
            activateFilterArea: 'area',
            projectQuoteStatusEnum: [
                ProjectQuoteStatusEnum.CANCELLED,
                ProjectQuoteStatusEnum.DRAFT_QUOTE,
                ProjectQuoteStatusEnum.QUOTE_SUBMITTED,
                ProjectQuoteStatusEnum.QUOTE_ACCEPTED,
                ProjectQuoteStatusEnum.QUOTE_REJECTED,
                ProjectQuoteStatusEnum.PROJECT_REJECTED,
            ]
        };
    },
    methods: {
        handleChangeFilter(filters) {
            this.$emit('filter-change', filters);
        },
    }
});
