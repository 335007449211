import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PROJECTS_STORE } from '../../../../store/modules/projects';
export default defineComponent({
    name: 'project-category-filter',
    emits: ['change'],
    props: {
        // Need to specify which field need to map for the display label category
        categoryLabelKey: {
            type: String,
            required: true,
        },
        panelHeight: {
            type: String,
            default: '600px'
        }
    },
    data() {
        return {
            selectedCategories: [],
            isIndeterminate: true,
            checkAll: false,
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, ['categories']),
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        searchCategoryFilter(selectedCategories) {
            this.selectedCategories = selectedCategories;
            this.emitFilterChange();
        },
        onCheckAllCategories(category) {
            this.selectedCategories = category.checked ? category.subCategories.map((item) => item.id) : [];
            // eslint-disable-next-line no-param-reassign
            category.isIndeterminate = false;
            this.emitFilterChange();
        },
        onCheckSubCategory(category) {
            const checkedCount = category.subCategories.length;
            // eslint-disable-next-line no-param-reassign
            category.checked = checkedCount === this.selectedCategories.length;
            // eslint-disable-next-line no-param-reassign
            category.isIndeterminate = checkedCount > 0 && checkedCount > this.selectedCategories.length;
            this.emitFilterChange();
        },
        emitFilterChange() {
            this.$emit('change', {
                subCategoryIds: this.selectedCategories,
            });
        }
    },
    async created() {
        // load the category records
        await this.getCategories();
    }
});
