import { PAGE_LIMIT } from '@/core/constants';
export default {
    data() {
        return {
            pageFilters: {
                page: 1,
                limit: PAGE_LIMIT,
            },
            // holds a copy of the page from response data
            pageListMetadata: null,
        };
    },
};
