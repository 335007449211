import { defineComponent } from 'vue';
import Expand from '@/core/components/ui/Expand.vue';
export default defineComponent({
    name: 'map-layout-tp-popup',
    emits: ['close'],
    components: {
        Expand,
    },
    props: {
        project: {
            type: Object,
            required: true,
            default: null,
        },
        averageQuoteValue: {
            type: Function,
        }
    },
    methods: {
        viewProject(projectId) {
            this.$router.push({ name: 'project-overview', params: { projectId } });
        },
        close() {
            this.$emit('close');
        }
    },
});
