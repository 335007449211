import * as mob from 'is-mobile';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProjectEmptySkeleton from '@/core/components/project/placeholder/ProjectEmptySkeleton.vue';
import ProjectCardLayoutA from '@/core/components/project/project-card-layouts/ProjectCardLayoutA.vue';
import ProjectMap from '@/core/components/project/project-map/ProjectMap.vue';
import ProjectContainerSlot from '@/core/components/project/ProjectContainerSlot.vue';
import Sort from '@/core/components/ui/Sort.vue';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import projectPageListMixin from '@/mixins/projects/project-page-list.mixin';
import ProjectSearchEmptyComponent from '@/modules/project-quotes/components/ProjectQuoteEmptyComponent.vue';
import ProjectQuoteFilterComponent from '@/modules/project-quotes/components/ProjectQuoteFilterComponent.vue';
import { PAGE_LIMIT } from '../../../core/constants';
import { PROJECTS_STORE } from '../../../store/modules/projects';
// eslint-disable-next-line import/no-cycle
import { PROJECT_QUOTES_ROUTE } from '../routes';
export default defineComponent({
    name: 'project-quotes-page',
    components: {
        ProjectContainerSlot,
        ProjectQuoteFilterComponent,
        ProjectEmptySkeleton,
        ProjectCardLayoutA,
        ProjectSearchEmptyComponent,
        Sort,
        ProjectMap,
    },
    mixins: [projectPageListMixin],
    data() {
        return {
            drawer: false,
            isAppendToBody: false,
            isLoadingProjects: null,
            projects: [],
            defaultFilters: {
                areaCodeFilter: {
                    postcode: '',
                    distance: 5
                },
            },
            projectListMetaData: {
                limit: PAGE_LIMIT,
                page: 1,
                totalCount: 0,
                totalPages: 0,
            },
            pageFilters: {
                page: 1,
                limit: PAGE_LIMIT,
                projectQuoteStatusIds: [],
            },
            defaultProjectQuoteStatusIds: [
                ProjectQuoteStatusEnum.CANCELLED,
                ProjectQuoteStatusEnum.DRAFT_QUOTE,
                ProjectQuoteStatusEnum.QUOTE_SUBMITTED,
                ProjectQuoteStatusEnum.QUOTE_ACCEPTED,
                ProjectQuoteStatusEnum.QUOTE_REJECTED,
                ProjectQuoteStatusEnum.PROJECT_REJECTED
            ]
        };
    },
    computed: {
        ...mapGetters(['generalSortFields']),
        ...mapGetters(PROJECTS_STORE, ['currentPageViewMapping']),
        isMapView() {
            const currentPageMapping = this.currentPageViewMapping;
            return currentPageMapping[PROJECT_QUOTES_ROUTE] === 'map-view';
        },
    },
    created() {
        this.checkIfUserIsVerified();
        this.initialize();
    },
    methods: {
        ...mapActions(['checkIfUserIsVerified']),
        ...mapActions(PROJECTS_STORE, ['getProjectQuotesList', 'setCurrentPageViewMapping']),
        initialize() {
            this.setProjectDrawerAppendToBody();
            this.setCurrentPageViewMapping({
                [PROJECT_QUOTES_ROUTE]: 'list-view'
            });
            this.loadProjects();
        },
        onPaginateList(currentPage) {
            this.pageFilters.page = currentPage;
            this.loadProjects();
        },
        loadProjects() {
            this.isLoadingProjects = true;
            const currentFilters = this.pageFilters;
            if (!currentFilters.projectQuoteStatusIds?.length) {
                currentFilters.projectQuoteStatusIds = this.pageFilters.projectQuoteStatusIds.concat(this.defaultProjectQuoteStatusIds);
            }
            this.getProjectQuotesList(currentFilters)
                .then((response) => {
                this.projects = response.data;
                const { limit, page, totalCount, totalPages } = response;
                this.projectListMetaData = {
                    limit,
                    page,
                    totalCount,
                    totalPages,
                };
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Project Quote List Error',
                    message: e?.data?.message || 'Error fetching of records at the moment. Please try to refresh the page',
                    duration: 4000,
                });
            })
                .finally(() => {
                this.isLoadingProjects = false;
            });
        },
        setProjectDrawerAppendToBody() {
            if (mob.isMobile()) {
                this.isAppendToBody = true;
            }
            else {
                this.isAppendToBody = false;
            }
        },
        navigateToProjectOverview(projectRefId) {
            this.$router.push(`/projects/${projectRefId}/project-details/overview`);
            // this.$router.push({ name: 'project-overview', params: { projectRefId } });
        },
        onFilterChange(filters) {
            this.pageFilters = Object.assign(this.pageFilters, { ...filters });
            // TODO: investigate why there's unnecessary field. Seems isTrusted is added directly in the object by axios or vue
            if ('isTrusted' in this.pageFilters) {
                delete this.pageFilters.isTrusted;
            }
            this.loadProjects();
        }
    },
});
